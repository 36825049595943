<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Subsubcategorias - Edição</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'product-subcategories'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-product-subcategory :productsubcategory="productsubcategory" :update="true"></form-product-subcategory>
</span>
</template>

<script>
import FormProductSubcategoryComponent from "./partial/FormProductSubcategoryComponent";

export default {
    name: "AddProductSubcategoryComponent",
    created() {
        this.loadProductSubcategory()
    },
    props: {
        prod_sc_id: {
            require: true
        }
    },
    data() {
        return {
            productsubcategory: {
                prod_sc_id: '',
                product_category_id: '',
                prod_sc_nome: '',
                prod_sc_slug: '',
                prod_sc_descricao: '',
                prod_sc_logo: '',
                prod_sc_banner: '',
                prod_sc_status: true,
                prod_sc_show_menu: true,
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            langs: true
        }
    },
    methods: {
        loadProductSubcategory() {
            this.$store.dispatch('loadProductSubcategory', this.prod_sc_id)
                .then(response => this.productsubcategory = response)
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        formProductSubcategory: FormProductSubcategoryComponent
    }
};
</script>

<style scoped>

</style>
